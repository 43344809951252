@import "../../../data/styles.css";

.feature-project {
	mix-blend-mode: normal;
	border-radius: 20px;
	height: 100%;
	position: relative;
	overflow: hidden;
}

.feature-project-thumbnail img {
	width: 100%;
	height: auto;
	display: block;
	transition: transform 0.3s ease;
}

.feature-project-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.6);
	color: var(--primary-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	opacity: 0;
	transition: opacity 0.3s ease;
}

.feature-project-description {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	margin-bottom: 0.5em;
	font-weight: 400;
	text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-align: center;
}

.learn-more-button {
	padding: 10px 15px;
	background-color: var(--link-color);
	border: none;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
	border-radius: 10px;
	transition: filter 0.3s;
}

.learn-more-button:hover {
	filter: brightness(70%);
}

.feature-project:hover .feature-project-overlay {
	opacity: 1;
}

.feature-project:hover .feature-project-thumbnail img {
	transform: scale(1.1);
}

@media (max-width: 600px) {
	.feature-project {
	}
}
