@import "../../../data/styles.css";


.project-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.project-modal-title {
    margin-bottom: 0.1rem;
    font-size: 2rem;
    font-weight: bold;
    font-family: var(--secondary-font);
    margin-top: 0;
}

.project-modal-description {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 1rem;
    color: #555;
}

.project-modal-tech-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.tech-tag {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.1);
    color: #333;
    padding: 5px 10px;
    border-radius: 20px;
    margin: 3px;
    font-size: 0.8rem;
}

.project-modal-image {
    max-width: 100%;
    max-height: 500px;
    border-radius: 5px;
}

.project-modal-slider {
    max-width: 100%;
    max-height: 500px;
    height: auto;
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #333;
}

.button-container {
    display: flex;
    gap: 15px;
    margin-top: 50px;
    align-self: flex-end;
}

.cta-btn {
    padding: 0.8rem 15px;
    font-weight: bold;
    line-height: 1;
    cursor: pointer;
    border-radius: 5px;
    background-color: #1976d2;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-btn:hover {
    background-color: #115293;
}

