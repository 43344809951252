@import "../../data/styles.css";

.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80% !important;
}

.download-resume-button {
	display: flex;
	align-items: center;
	background-color: var(--link-color);
	color: #fff;
	text-decoration: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin: 40px 0 20px;
	width: 40%;
	transition: filter 0.3s;
}

.download-resume-button:hover {
	filter: brightness(90%);
}

.button-icon {
	margin-right: 10px;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	flex-basis: 600px;
	padding-top: 50px;
	padding-bottom: 50px;
}

.software-skills-main-div {
	margin-top: 2rem;
}

.skill-title {
	font-size: 24px !important;
}

.dev-icons {
	padding-left: 0;
	list-style: none;
	font-size: 3rem;
	margin-bottom: 0;
	margin-top: 1rem;
	text-align: center;
}

.software-skill-inline {
	display: inline-block;
	margin-right: 20px;
	margin-bottom: 20px;
}

.skill-icon {
	color: var(--tertiary-color);
	transition: color 0.3s ease;
}

.skill-icon:hover {
	color: var(--link-color);
}

.software-skill-inline > p {
	color: var(--tertiary-color);
	font-size: 10px;
}

.skill-icon:hover ~ p {
	color: var(--link-color);
}


@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-subtitle {
		padding-top: 20px !important;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 1;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-container {
		padding-bottom: 40px;
	}

	.about-socials {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}

	.about-socials-mobile {
		padding-top: 30px;
		display: flex;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}
}
